"use client";
/* eslint-disable @next/next/no-img-element */
import { stringToHex } from "../helpers/stringToHex";

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn } from "@/utils/cn";

const AvatarElement = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn(
            "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
            className
        )}
        {...props}
    />
));
AvatarElement.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={cn("aspect-square h-full w-full", className)}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn(
            "flex h-full w-full items-center justify-center rounded-full bg-slate-100 dark:bg-slate-700",
            className
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

function hex_is_light(color: string) {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
}

export const Avatar = ({
    username,
    image,
    size,
}: {
    username: string;
    image?: string | null;
    size?: number;
}) => {
    const avatar_size = size || 10;
    const user_initials = username
        .split(" ")
        .map((n) => n[0])
        .join("");
    const user_color = stringToHex(username).toString();
    const is_light = hex_is_light(user_color);

    return (
        <AvatarElement>
            <AvatarImage src={image || ""} alt={username} />
            <AvatarFallback
                className={`w-${avatar_size} ring-1 ring-white ${
                    is_light ? "text-black" : "text-white"
                }`}
                style={{
                    background: user_color,
                }}
            >
                {user_initials}
            </AvatarFallback>
        </AvatarElement>
    );
};
