import React from "react";
import { signOut, useSession } from "next-auth/react";
import tw from "tailwind-styled-components";
import { useTranslation } from "next-i18next";
import Link from "next/link";
const Skeleton = await (await import("react-loading-skeleton")).default;
import {
    CheckSquare,
    LogOut,
    Music4,
    UserCheck,
    CalendarCheck2,
    Gauge,
} from "lucide-react";

import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/molecules/DropdownMenu";
import { LogoWithText } from "@/components/molecules/LogoWithText";
import { useGroupStore } from "@/components/states/group";
import { NavBar } from "./NavBar";
import { Avatar } from "@/components/molecules/Avatar";
import { Users } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/router";
import { Badge } from "../ui/Badge";
import { Logo } from "../ui/Logo";
import { Separator } from "../ui/separator";
import {
    PermisisonTargets,
    Permission,
    PermissionAction,
    buildPermission,
} from "@/utils/permissions";

export const MainContainer = tw.div`
    min-h-screen
`;

export const MainContent = tw.div`
    w-full
    lg:w-9/12
    md:w-10/12
    mx-auto
    px-5
    md:px-0
    min-h-screen
    pb-10
    space-y-4
    sm:py-6
    py-16
`;

export const FullMainContent = tw.div`
    mx-auto
    px-5
    md:px-0
    min-h-screen
    pb-10
`;

export const FluidMainContent = tw.div`
    mx-auto
    md:px-0
    min-h-screen
`;

export const FullWidthMainContent = tw(FluidMainContent)`
    mx-0
`;

export interface MainLayoutProps {
    content: React.ReactElement;
}

export const MainNavbar = () => {
    const router = useRouter();
    const { data, status } = useSession();
    const { t, i18n } = useTranslation("common");
    const groupOid = useGroupStore((state) => state.groupOid);
    const setGroup = useGroupStore((state) => state.setGroup);
    const groupRole = useGroupStore((state) => state.groupRole);
    const groupPermission = useGroupStore((state) => state.groupPermission);
    const perm = buildPermission(groupPermission);

    const MenuItems = [
        {
            children: [
                {
                    active_paths: ["/dashboard"],
                    link: "/dashboard",
                    label: t`Dashboard`,
                    icon: <Gauge className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />,
                    role: [],
                },
            ],
        },
        {
            label: t`Tools`,
            children: [
                {
                    active_paths: ["/events", "/event"],
                    link: "/events",
                    label: t`Events`,
                    icon: (
                        <CalendarCheck2 className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />
                    ),
                    role: [PermisisonTargets.events, PermissionAction.view],
                },
                {
                    active_paths: ["/songs", "/song"],
                    link: "/song",
                    label: t`Songs`,
                    icon: <Music4 className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />,
                    role: [PermisisonTargets.songs, PermissionAction.view],
                },
            ],
        },
        {
            label: t`Configuration`,
            icon: <Users />,
            children: [
                {
                    active_paths: ["/group/users"],
                    link: "/group/users",
                    label: t`Users`,
                    icon: <Users className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />,
                    role: [PermisisonTargets.users, PermissionAction.view],
                },
                {
                    active_paths: ["/group/roles"],
                    link: "/group/roles",
                    label: t`User Roles`,
                    icon: (
                        <UserCheck className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />
                    ),
                    role: [PermisisonTargets.roles, PermissionAction.view],
                },
                {
                    active_paths: ["/group/worship/roles"],
                    link: "/group/worship/roles",
                    label: t`Event Roles`,
                    icon: (
                        <CheckSquare className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />
                    ),
                    role: [PermisisonTargets.events, PermissionAction.view],
                },
            ],
        },
    ];

    const LanguageItems = [
        {
            language: "hu",
            label: t(`Hungarian`),
        },
        {
            language: "en",
            label: t(`English`),
        },
    ];

    const isActive = (link: string) => {
        return router.asPath.startsWith(link);
    };

    const onGroupChange = (group: any) => () => {
        setGroup(group);
    };

    return (
        <NavBar>
            {/* Site branding */}
            {/* <div className="mr-4 hidden flex-shrink-0 xl:block"> */}
            <div className="mr-4 flex-shrink-0">
                {/* Logo */}
                <Link href={data ? "/dashboard" : "/"} passHref>
                    <div className="hidden xl:block">
                        <LogoWithText />
                    </div>
                    <div className="mx-auto block p-3 text-center xl:hidden">
                        <Logo />
                    </div>
                </Link>
            </div>

            <div className="flex-grow py-0 xl:py-4">
                {MenuItems.map((item, index) => {
                    return (
                        <div className="" key={index}>
                            <h2 className="px-2 text-lg font-semibold tracking-tight">
                                <Separator className="my-3 opacity-25" />
                                <span className="block sm:hidden xl:block">
                                    {item.label}
                                </span>
                                {item.children.length > 0 &&
                                    item.children.map(
                                        (children, children_index) => {
                                            const isLinkActive =
                                                children.active_paths
                                                    .map(isActive)
                                                    .filter((v) => v == true)
                                                    .length > 0;

                                            let hasPermission = true;
                                            if (
                                                children.role &&
                                                children.role.length > 0
                                            ) {
                                                hasPermission = perm(
                                                    children
                                                        .role[0] as PermisisonTargets,
                                                    children
                                                        .role[1] as PermissionAction
                                                );
                                            }

                                            if (!hasPermission) return;

                                            return (
                                                <Link
                                                    href={children.link}
                                                    legacyBehavior
                                                    passHref
                                                    key={children_index}
                                                >
                                                    <a className="flex flex-row gap-2">
                                                        <Button
                                                            variant={
                                                                isLinkActive
                                                                    ? "blue"
                                                                    : "ghost"
                                                            }
                                                            size="sm"
                                                            className="w-full justify-start p-3 sm:justify-center sm:p-0 xl:justify-start xl:p-3"
                                                        >
                                                            {children.icon}
                                                            <span className="block sm:hidden xl:block">
                                                                {children.label}
                                                            </span>
                                                        </Button>
                                                    </a>
                                                </Link>
                                            );
                                        }
                                    )}
                            </h2>
                        </div>
                    );
                })}
            </div>

            {/* Site navigation */}
            <nav className="flex justify-start">
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <div className="rounded px-2 py-0 hover:bg-slate-200">
                            <Avatar
                                username={data?.user?.name || ""}
                                image={data?.user?.image || ""}
                            />
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-full">
                        <DropdownMenuGroup>
                            <DropdownMenuLabel>{t`Groups`}</DropdownMenuLabel>
                            {!data && (
                                <DropdownMenuItem>
                                    <Skeleton />
                                </DropdownMenuItem>
                            )}
                            {data &&
                                data?.groups?.map((group, index) => {
                                    return (
                                        <DropdownMenuCheckboxItem
                                            key={index}
                                            checked={groupOid == group.oid}
                                            onCheckedChange={onGroupChange(
                                                group
                                            )}
                                        >
                                            {group.name}
                                        </DropdownMenuCheckboxItem>
                                    );
                                })}
                            <DropdownMenuSeparator />
                            <DropdownMenuLabel>{t`Languages`}</DropdownMenuLabel>
                            {LanguageItems.map((item, index) => {
                                return (
                                    <Link
                                        key={index}
                                        href="#"
                                        locale={item.language}
                                        passHref
                                        legacyBehavior
                                    >
                                        <a>
                                            <DropdownMenuCheckboxItem
                                                checked={
                                                    i18n.language ==
                                                    item.language
                                                }
                                            >
                                                {item.label}
                                            </DropdownMenuCheckboxItem>
                                        </a>
                                    </Link>
                                );
                            })}
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={() => signOut()}>
                                <LogOut className="mr-2 h-4 w-4" />
                                {t`Logout`}
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem className="flex-col items-start gap-1">
                                <strong>{data?.user?.name}</strong>
                                <Badge>{groupRole}</Badge>
                            </DropdownMenuItem>
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </nav>
        </NavBar>
    );
};

export const MainLayout = (props: MainLayoutProps): React.ReactElement => {
    return (
        <MainContainer>
            <div className="flex gap-0 xl:gap-4">
                <MainNavbar />
                <MainContent>{props.content}</MainContent>
            </div>
        </MainContainer>
    );
};
