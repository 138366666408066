export const fetcher = async (url: string) =>
    await fetch(url).then((res) => res.json());

export const postWithValidate = async (url: string, postData: any) => {
    const req = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(postData),
    });
    if (req.status !== 200) return false;
    const data = req.body;
    return data;
};
