import { useTranslation } from "next-i18next";
import { Logo } from "../ui/Logo";

export const LogoWithText = ({ style }: { style?: "light" | "dark" }) => {
    const { t } = useTranslation("common");

    return (
        <div className="flex gap-2">
            <Logo />
            <div
                className={
                    "flex flex-col justify-center text-left dark:text-white " +
                    (style == "dark" ? "text-white" : "")
                }
            >
                <div className="font-extrabold leading-5">WorshipMS</div>
                <div className="hidden text-xs leading-5 sm:block">
                    {t`Online management system for Churches`}
                </div>
            </div>
        </div>
    );
};
