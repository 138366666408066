import { flatten } from "lodash";
const PermissionSet = await (await import("flexperm")).PermissionSet;
// var flexperm = require('flexperm');

export enum PermisisonTargets {
    events = 'events',
    songs = 'songs',
    users = 'users',
    roles = 'roles',
}

export enum PermissionAction {
    create = 'create',
    edit = 'edit',
    delete = 'delete',
    view = 'view',
}

export enum Permission {
    admin = 'admin',
    // EVENTS
    events_create = 'events_create',
    events_edit = 'events_edit',
    events_delete = 'events_delete',
    events_view = 'events_view',

    // SONGS
    songs_create = 'songs_create',
    songs_edit = 'songs_edit',
    songs_delete = 'songs_delete',
    songs_view = 'songs_view',

    // USERS
    users_create = 'users_create',
    users_edit = 'users_edit',
    users_delete = 'users_delete',
    users_view = 'users_view',

    // ROLES
    roles_create = 'roles_create',
    roles_edit = 'roles_edit',
    roles_delete = 'roles_delete',
    roles_view = 'roles_view',
}

const PERMISSION_CONFIG = flatten(Object.keys(PermisisonTargets).map((key) => ([
        {
            target: key,
            match: {
                [`${key}_create` as Permission]: true
            },
            grant: {
                [PermissionAction.create]: true,
                [PermissionAction.view]: true
            }
        },
        {
            target: key,
            match: {
                [`${key}_edit` as Permission]: true
            },
            grant: {
                [PermissionAction.edit]: true,
                [PermissionAction.view]: true
            }
        },
        {
            target: key,
            match: {
                [`${key}_delete` as Permission]: true
            },
            grant: {
                [PermissionAction.delete]: true,
                [PermissionAction.view]: true
            }
        },
        {
            target: key,
            match: {
                [`${key}_view` as Permission]: true
            },
            grant: {
                [PermissionAction.view]: true
            }
        },
        {
            target: key,
            match: {
                [Permission.admin]: true
            },
            grant: {
                [PermissionAction.edit]: true,
                [PermissionAction.view]: true,
                [PermissionAction.delete]: true,
                [PermissionAction.create]: true,
            }
        }
    ])))

export const buildPermission = (userData: string[] = []) => (event: PermisisonTargets, action: PermissionAction) => {
    try {
        const permSet = new PermissionSet(PERMISSION_CONFIG)
        const userDataObject = userData.reduce((a,v) => ({...a, [v]: true}), {})
        return permSet.getTargetGrant(event, userDataObject).check(action)
    } catch (error) {
        return false;
    }
}