import { cn } from "@/utils/cn";
import { Menu, X } from "lucide-react";
import { useEffect, useState } from "react";

export const NavBar: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [top, setTop] = useState(true);
    const [menuOpen, setMenuOPen] = useState(false);

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);
    }, [top]);

    return (
        <div className="sticky left-0 top-0 z-50 max-w-[82px] flex-col xl:flex xl:w-2/12  xl:max-w-[fit-content] ">
            <div
                className="absolute z-10 block p-5 sm:hidden"
                onClick={() => setMenuOPen(!menuOpen)}
            >
                {!menuOpen && <Menu />}
                {menuOpen && <X className={" bg-slate-950 text-white"} />}
            </div>
            <div
                className={cn(
                    "absolute left-0 top-0 h-screen w-full min-w-fit max-w-fit flex-col border-r bg-slate-950 p-4 text-white sm:sticky sm:min-w-[82px] sm:p-1 xl:min-w-[250px] xl:p-4",
                    menuOpen ? "flex" : "hidden",
                    "sm:flex"
                )}
            >
                {children}
            </div>
        </div>
    );
};
