import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createGroup } from "../events/groups";
import { Session } from "next-auth";

export interface GroupState {
    groupOid: string;
    groupName: string;
    groupPermission: string[],
    groupRole: string,
    setGroupName: (name: string) => void;
    createNew: (newGroupName: string) => Promise<boolean>;
    setGroup: (group: NonNullable<Session["groups"]>[0]) => void;
}

export const useGroupStore = create<GroupState>()(
    persist(
        (set, get) => ({
            groupOid: "",
            groupName: "",
            groupPermission: [],
            groupRole: "",
            setGroupName: (name: string) =>
                set((state) => ({ ...state, groupName: name })),
            setGroup: (groupData) =>
                set((state) => ({
                    ...state,
                    groupOid: groupData.oid,
                    groupName: groupData.name,
                    groupPermission: groupData.permissions,
                    groupRole: groupData.role
                })),
            createNew: async (newGroupName: string): Promise<boolean> => {
                const response = await createGroup(newGroupName);
                if (!response) {
                    return false;
                }

                return true;
            },
        }),
        {
            name: "group-storage",
        }
    )
);
